//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, onBeforeUnmount, computed } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkFeaturePanel',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    desktopImage: {
      type: String,
      default: '',
    },
    desktopImageRetina: {
      type: String,
      default: '',
    },
    tabletImage: {
      type: String,
      default: '',
    },
    tabletImageRetina: {
      type: String,
      default: '',
    },
    mobileImage: {
      type: String,
      default: '',
    },
    mobileImageRetina: {
      type: String,
      default: '',
    },
    alt: {
      type: [String, null],
      default: '',
    },
    width: {
      type: [String, Number],
      default: '',
    },
    height: {
      type: [String, Number],
      default: '',
    },
    orderReversed: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Boolean,
      default: false,
    },
    listItems: {
      type: Array,
      default: () => [],
    },
    isListDots: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const wrapper = computed(() => {
      return props.link ? 'div' : 'div';
    });

    return {
      wrapper,
    };
  },
});
