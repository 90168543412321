//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import merge from 'lodash.merge';
import { defineComponent, ref, computed, reactive } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'RkPicture',
  props: {
    src: {
      type: String,
      required: true,
    },
    responsiveImages: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alt: {
      type: [String, null],
      default: '',
    },
    width: {
      type: [String, Number],
      default: '',
    },
    height: {
      type: [String, Number],
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
      validator: (value) => ['', 'lazy', 'eager'].includes(value),
    },
  },
  setup(props) {
    const loaded = ref(false);

    const placeholder = ref({
      type: String,
      default: '',
    });

    const responsiveImagesDefault = reactive({
      mobile: {
        media: '(max-width: 641px)',
        default: '',
        retina: '',
        width: '',
        height: '',
      },
      tablet: {
        media: '(min-width: 641px)',
        default: '',
        retina: '',
      },
      desktop: {
        media: '(min-width: 1024px)',
        default: '',
        retina: '',
      },
    });

    const mergedResponsiveImages = computed(() => {
      const merged = merge(responsiveImagesDefault, props.responsiveImages);
      return merged;
    });

    const classes = computed(() => {
      if (loaded.value) {
        return 'rk-picture rk-picture--loaded';
      } else {
        return 'rk-picture';
      }
    });

    const onLoad = () => {
      loaded.value = true;
    };

    return {
      loaded,
      mergedResponsiveImages,
      computed,
      onLoad,
      classes,
      placeholder,
    };
  },
});
