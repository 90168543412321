import { render, staticRenderFns } from "./RkFeaturePanel.vue?vue&type=template&id=b89ac888"
import script from "./RkFeaturePanel.vue?vue&type=script&lang=js"
export * from "./RkFeaturePanel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkPicture: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkPicture/RkPicture.vue').default,RkHeading: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkHeading/RkHeading.vue').default,RkListItem: require('/var/www_vsf/htdocs/rockitUI/components/components/organisms/RkList/_internal/RkListItem.vue').default,RkList: require('/var/www_vsf/htdocs/rockitUI/components/components/organisms/RkList/RkList.vue').default})
