import { render, staticRenderFns } from "./FeaturePanel.vue?vue&type=template&id=9f5c9660"
import script from "./FeaturePanel.vue?vue&type=script&lang=js"
export * from "./FeaturePanel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkFeaturePanel: require('/var/www_vsf/htdocs/rockitUI/components/components/molecules/RkFeaturePanel/RkFeaturePanel.vue').default})
