var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto max-w-outer"},[_c('section',{staticClass:"rk-feature-panel lg:grid lg:grid-cols-2 lg:items-center"},[_c('div',{staticClass:"rk-feature-panel__image",class:{ 'order-2': _vm.orderReversed }},[_vm._t("image",function(){return [_c('RkPicture',{attrs:{"src":_vm.image,"responsiveImages":{
            mobile: {
              default: _vm.mobileImage,
              retina: _vm.mobileImageRetina,
            },
            desktop: {
              default: _vm.desktopImage,
              retina: _vm.desktopImageRetina,
            },
          },"alt":_vm.alt,"width":_vm.width,"height":_vm.height}})]},null,{ image: _vm.image })],2),_vm._v(" "),_c('div',{staticClass:"rk-feature-panel__content px-3 py-6 md:max-w-md md:px-6 lg:ml-20 lg:mr-20 lg:px-0",class:{ 'lg:order-1 lg:justify-self-end': _vm.orderReversed }},[_vm._t("title",function(){return [(_vm.title)?_c('RkHeading',{staticClass:"rk-feature-panel__title mb-5",attrs:{"level":2,"size":4}},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]):_vm._e()]},null,{ title: _vm.title }),_vm._v(" "),_vm._t("description",function(){return [(_vm.description)?_c('p',{staticClass:"rk-feature-panel__description mb-5 lg:text-1xl"},[_vm._v("\n          "+_vm._s(_vm.description)+"\n        ")]):_vm._e()]},null,{ description: _vm.description }),_vm._v(" "),_vm._t("call-to-action"),_vm._v(" "),_vm._t("list",function(){return [(_vm.list && _vm.listItems.length > 0)?_c('RkList',{class:{ 'list-inside list-disc': _vm.isListDots }},_vm._l((_vm.listItems),function(item,key){return _c('RkListItem',{key:key},[_vm._v("\n            "+_vm._s(item)+"\n          ")])}),1):_vm._e()]},null,{ list: _vm.list })],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }